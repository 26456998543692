import React from "react"
import { graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"

import KeymanPage from "../components/common/keyman-page"
import { KEYMAN_PER_PAGE } from "../constants/constants"

export const query = graphql`
  query Keymans2Query($slug: String) {
    keymans2Json(fields: { slug: { eq: $slug } }) {
      id
      corporation_id
      relocation_at
      name
      honorific
      corporate_number
      company_name
      prefecture_name
      city_name
      street_number
      corporation_en_name
      corporation_furigana
      change_cause
      listing_type
      capital_stock
      amount_of_sales
      number_of_employees
      logo_path
      corporation_detail_detail
      average_age
      annual_income
      clothing_month
      source_url
      corporation_source_url
      patented
      registered_design
      registered_trademark
      shikiho_stock_code
      shikiho_site_url
      shikiho_feature
      # shikiho_consolidated_business
      # shikiho_profile
      shikiho_tel
      shikiho_main_office
      # shikiho_topic1_content
      # shikiho_topic2_content
      # furigana,
      # birthday,
      # from,
      # junior_high_school,
      # high_school,
      # technical_college,
      # professional_school,
      # university,
      # grad_school,
      financial_statements
      # executive_interviews
      executive_biographies
      shikiho_performances
      has_intent_tag
      has_public_department_phone_number
    }
  }
`

const mapStateToProps = (state, ownProps) => {
  return {
    keymans: state.keyman.keymans.toIndexedSeq().toArray(),
    pageInfo_keyman: state.keyman.pageInfo_keyman,
    isLoadingKeymans: state.keyman.isLoadingKeymans,
    isLoadingDepartmentRank: state.department.isLoadingDepartmentRank,
    departmentRankStats: state.department.rankStats,
    isLoadingJsicSRank: state.industry.isLoadingJsicSRank,
    jsicSRankStats: state.industry.jsicSRankStats,
    executiveCorporationTotal: state.executiveCounts.executiveCorporationTotal,
    executiveTotal: state.executiveCounts.executiveTotal,
    isInitialized: state.keyman.isInitialized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
    getRankDepartmentsById: id =>
      dispatch(actions.getRankDepartmentsById(id, null)),
    getRankJsicSById: id => dispatch(actions.getRankJsicSById(id, null)),
    getExecutiveCount: () => dispatch(actions.getExecutiveCount()),
  }
}

const styles = theme => ({})

class Keyman2 extends React.Component {
  componentDidMount() {
    this.props.getKeymans(
      {
        corporation_id: [this.props.data.keymans2Json.corporation_id],
        per_page: KEYMAN_PER_PAGE,
        paginng: 1,
        page: 1,
        exclude_keyman_id: [this.props.data.keymans2Json.id],
      },
      true
    )

    this.props.getExecutiveCount()
  }

  render() {
    const {
      data,
      isLoadingKeymans,
      departmentRankStats,
      getRankDepartmentsById,
      jsicSRankStats,
      getRankJsicSById,
      executiveCorporationTotal,
      executiveTotal,
      isInitialized,
    } = this.props
    const keymans =
      (!isInitialized) ||
      (isLoadingKeymans && this.props.keymans && this.props.keymans.length === 0)
        ? Array.from(Array(1))
        : this.props.keymans

    return (
      <KeymanPage
        keymansJson={data.keymans2Json}
        keymans={keymans}
        departmentRankStats={departmentRankStats}
        getRankDepartmentsById={getRankDepartmentsById}
        jsicSRankStats={jsicSRankStats}
        getRankJsicSById={getRankJsicSById}
        executiveCorporationTotal={executiveCorporationTotal}
        executiveTotal={executiveTotal}
      />
    )
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Keyman2)
)
